.app {
  text-align: center;
}
.app-main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
}
.app-link {
  color: #61dafb;
}

/* App Header */
.logo-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 0;
}

.app-logo {
  height: calc(30px + 4vw);
  pointer-events: none;
}

/* Animated Buffering Circle (Loader) */
.loader {
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid red;
  width: 200px;
  height: 200px;
  animation: spinner 4s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.summary-loader {
  margin-top: 50px;
}
.download-loader {
  width: 150px;
  height: 150px;
}

/* Result Section Boxes */
.result {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 15px 0px;
}
.main-box-outer {
  width: 50%;
}
.main-box-inner {
  font-size: calc(5px + 2vmin);
  text-align: left;
  margin: 0;
  border-radius: 8px;
  background-color: white;
  color: black;
  height: 25vw;
  overflow: scroll;
}
.section-title {
  font-size: calc(20px + 2vw);
  margin: 2rem;
}

/* Original Video Section */
.video-box {
  overflow: hidden;
  background-color: var(--bg-color);
}

.skeleton-loader {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  animation: shimmer 1.5s infinite linear;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

/* Download Modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 10px;
  z-index: 10;
  width: 600px;
  max-width: 50%;
  padding: 20px;
}
.modal.active {
  transform: translate(-50%, -50%) scale(1);
}
#overlay {
  position: fixed;
  /* opacity: 0; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.overlay.active {
  opacity: 1;
  pointer-events: all;
}

/* Download Result */
#download-result {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.25rem;
}
progress[value] {
  --w: 200px; /* the width*/
  --color:  /* the progress color */ linear-gradient(#fff8, #fff0),
    repeating-linear-gradient(135deg, #0003 0 10px, #0000 0 20px),
    /* if < 30% "red" */ linear-gradient(red 0 0) 0 /
      calc(var(--w) * 0.3 - 100%) 1px,
    /* if < 60% "orange" */ linear-gradient(orange 0 0) 0 /
      calc(var(--w) * 0.6 - 100%) 1px,
    /* else "green" */ green;
  --background: lightgrey; /* the background color */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: var(--w);
  margin: 0 10px;
  border-radius: 10em;
  background: var(--background);
}
.progress-bar {
  position: relative;
  width: 200px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
}
.progress-fill {
  height: 30px;
  line-height: 20px;
  border-radius: 5px;
  background: aqua;
}
.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  margin: 0;
  font-size: 15px;
}
.download-text {
  margin: 0;
}
.inverted-icon {
  filter: brightness(0) invert(1);
}
.download-icon-large {
  width: 40px;
  height: 40px;
}

/* Popular Comments Section */
.comment-box {
  padding: 0;
}
.comment-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0.5rem 2rem;
  list-style-position: inside;
}
.comment-item {
  display: flex;
  justify-content: left;
  align-items: top;
  gap: 1rem;
}
.comment-profile {
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  object-fit: cover;
}
.comment-main {
  display: flex;
  justify-content: left;
  align-items: top;
  flex-direction: column;
  margin-top: 0;
  gap: 0.2rem;
}
.comment-header {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}

/* Summary Sections */
.summary {
  padding: 0.5rem 2rem;
}

/* Medium-sized devices (1200px and below) */
@media only screen and (max-width: 1200px) {
  .result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0;
  }

  .main-box-outer {
    width: 100%;
  }

  .section-title {
    font-size: calc(30px + 2vw);
  }

  .video-box {
    min-height: 37.5vw;
  }

  .comment-box {
    height: 50vw;
  }

  .text-box {
    height: 100%;
  }
}

/* Mobile devices (600px and below) */
@media only screen and (max-width: 600px) {
  .app-logo {
    height: calc(30px + 15vw);
  }

  .modal {
    max-width: 90%;
  }

  .result {
    margin: 0 5%;
  }

  .section-title {
    font-size: calc(18px + 2vw);
  }

  #download-title {
    gap: 0.5rem;
  }

  .logo-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;
  }

  .link-form {
    flex-direction: column;
    gap: 0.75rem;
  }

  .main-box-outer {
    width: calc(250px + 20vw) !important;
    margin: 0 10% !important;
  }

  .input-box {
    font-size: calc(10px + 2vw);
    max-width: 70%;
  }

  .video-box {
    min-height: 45vw;
  }

  .comment-box {
    height: 75vw;
  }
}

/* ANIMATIONS */

/* Define the slide-up animation */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100px); /* Start below the viewport */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End in the normal position */
  }
}

/* Create a reusable class for the slide-up animation */
.slide-up {
  opacity: 0; /* Initially hidden */
  animation: slideUp 1s ease-out forwards;
  /* 1s for duration, ease-out for smooth exit, forwards to keep final state */
}
